var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navigate-side-bar" },
    [
      _c("StudentArchives"),
      _c(
        "div",
        { staticClass: "navigate-list" },
        _vm._l(_vm.navigates, function (navigate) {
          return _c(
            "div",
            {
              key: navigate.key,
              staticClass: "navigate-list-item",
              on: {
                click: function () {
                  return _vm.onNavigateClick(navigate)
                },
                mouseenter: function () {
                  return _vm.onNavigateHover(navigate)
                },
                mouseleave: function () {
                  return _vm.onNavigateHover()
                },
              },
            },
            [
              _c("div", {
                staticClass: "icon",
                style: {
                  backgroundImage:
                    "url(" +
                    (navigate.active || navigate.hover
                      ? navigate.icon1
                      : navigate.icon0) +
                    ")",
                },
              }),
              _c(
                "span",
                {
                  staticClass: "label",
                  class: navigate.active ? "active" : "",
                },
                [_vm._v(_vm._s(navigate.title))]
              ),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }