<script>
import { mapState } from 'vuex'
import { models } from '@qx-student-client/common'
import { RouteNames } from '@/router'

export default {
  data() {
    return { models }
  },
  computed: {
    ...mapState({
      /**
       * @returns {import("@qx-student-client/common").models.User & import("@qx-student-client/common").models.Authentication} 当前用户
       */
      userInfo: state => state.userInfo,
    }),

    /**
     * @returns {(import("@qx-student-client/common").models.User & import("@qx-student-client/common").models.Authentication)['tenantList'][number]['roleConfig']} 当前公司
     */
    currentUserTenant() {
      return this.$store.getters.currentUserTenant
    },
  },

  methods: {
    onIntegralClick() {
      this.$router.push({ name: RouteNames.StudentCenterIntegral })
    },
    onCertClick() {
      this.$router.push({ name: RouteNames.StudentCenterCert })
    },
  },
}
</script>

<template>
  <div class="student-archives">
    <img v-ali-oss v-avatar-load-error class="student-avatar" :src="userInfo.avatar" alt="" />
    <span class="student-name">{{ userInfo.realName }}</span>
    <div class="archives" style="display: none;">
      <div class="archive-item" @click="onIntegralClick">
        <img class="archive-item-icon" src="./assets/image/icon-integral-mini.png" alt="" />
        <span class="archive-item-label">积分 {{ userInfo.stat.score }}</span>
      </div>
      <div v-if="currentUserTenant.certificateFlag === models.Enums.Flag.开启" class="archive-item" @click="onCertClick">
        <img class="archive-item-icon" src="./assets/image/icon-certificate-mini.png" alt="" />
        <span class="archive-item-label">证书 {{ userInfo.stat.certificateCount }}</span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.student-archives {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding: 20px 24px 30px;

  .student-avatar {
    width: 90px;
    height: 90px;
    object-fit: fill;
    border-radius: 90px;
  }

  .student-name {
    font-weight: bold;
    font-size: 18px;
  }

  .archives {
    display: flex;
    width: 100%;

    .archive-item {
      display: flex;
      flex: 1;
      gap: 0 8px;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &-icon {
        position: relative;
        top: 1px;
        width: 14px;
        height: 16px;
      }

      &-label {
        display: flex;
        align-items: center;
        color: #666;
        line-height: 16px;
      }

      .archive-item-label {
        transition: all .2s;

        &:hover {
          color: $--color-primary;
        }
      }
    }
  }
}
</style>
