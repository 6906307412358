import { request } from '@qx-student-client/common'
import { Message } from 'element-ui'
import router, { RouteNames } from './router'
import store from './store'

/**
 * 用于标识是否需要进行登录页面的跳转
 */
let inLoginPage = false
/**
 * 用于标识是否正在展示失败的信息
 */
let showMessage = false

request.request.interceptors.request.use(async (config) => {
  // 在请求调试器中添加师带徒模块的身份请求逻辑
  if (config) {
    if (config.url.startsWith('/relationship/relationship')) {
      const { code, data } = await request.masterAndApprentice.getCurrentStudentIdentity()
      if (code === 200) {
        const userInfo = store.state.userInfo
        const masterApprentice = userInfo.masterApprentice

        if (masterApprentice.userIs !== data.userIs || masterApprentice.masterApprenticeId !== data.masterApprenticeId) {
          // 如果身份或者ID发生了变化
          if (data.userIs === 1) { // 已经不是师傅或者徒弟的身份了，跳转到首页并进行提示
            Message.warning({ message: '您已被移除师带徒身份，暂不可以进行相关操作', offset: 100, duration: 1500 })
            store.commit('SET_USER_INFO', { ...userInfo, masterApprentice: data })
          }
          else {
            // 更换了身份或者删除后有添加了
            // 重置用户信息并刷新页面
            Message.warning({ message: '您的账号的师带徒身份发生变动，请在页面刷新后执行操作', offset: 100, duration: 1500 })
            setTimeout(() => {
              location.reload()
            }, 1500)
          }
        }
      }
    }
  }
  return config
})

request.request.interceptors.response.use(async (response) => {
  /**
   * @type {import('@qx-student-client/common/dist/models/HTTPResponse').HTTPResponse}
   */
  const responseData = response.data

  if (responseData.code !== 200) {
    // 如果返回401则表示用户账号出了问题，需要重新登录
    if (responseData.code === 401) {
      await store.dispatch('setUserInfo')
      if (!inLoginPage) {
        router.replace({ name: RouteNames.Login })
        inLoginPage = true
      }

      if (!showMessage) {
        showMessage = true
        Message.warning({
          message: responseData.msg,
          offset: 100,
          onClose: () => {
            showMessage = false
          },
        })
      }
    }
    else {
      const params = response.config ? response.config.method === 'get' ? (response.config.params || {}) : (response.config.data || {}) : {}

      if (!showMessage && !params._s) {
        showMessage = true
        Message.warning({
          message: responseData.msg,
          offset: 100,
          onClose: () => {
            showMessage = false
          },
        })
      }
    }
  }
  else {
    inLoginPage = false
  }

  return response
})
