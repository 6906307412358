if (location.pathname === '/virtually-path/message') {
  const params = new URLSearchParams(location.search)
  const queryParams = Object.fromEntries(params.entries())

  localStorage.setItem('/virtually-path/message', JSON.stringify(queryParams))

  // 清空一下地址栏
  if (queryParams.token) {
    window.history.replaceState({}, '', `/?token=${queryParams.token}`)
  }
  else {
    window.history.replaceState({}, '', '/#/')
  }
}
