// 消息跳转存储
import './message-jump'
import './device-detect'
import './polyfills'
import Vue from 'vue'
import ElementUI from 'element-ui'
import Avue from '@smallwei/avue'
import 'element-ui/packages/theme-chalk/src/index.scss'
import '@smallwei/avue/lib/index.css'
import 'swiper/css/bundle'
import '@/styles/element-ui-overview.scss'
import '@/styles/avue.scss'

import { models, request, utils } from '@qx-student-client/common'
import CompositionApi from '@vue/composition-api'
import Cookies from 'js-cookie'
import App from './App.vue'
import router from './router'
import store from './store'

import './interceptor'
import { env } from './env'

Vue.config.productionTip = false

Vue.use(CompositionApi)
Vue.use(ElementUI)
Vue.use(Avue)

Vue.use({
  install(Vue) {
    Vue.prototype.$env = env
    Vue.prototype.$models = models
  },
})

const token = Cookies.get(utils.Constants.COOKIE_TOKEN_KEY)

function isEthing() {
  if (location.search.length > 1) {
    const params = {}
    location.search.slice(0, -1).split('&').forEach((p) => {
      const pp = p.split('=')
      params[pp[0]] = pp[1]
    })

    return !!params.token
  }

  return false
}
// 每次初始化加载的时候进行更新信息
store.dispatch('init1')
  .then(() => {
    if (token && !isEthing()) {
      return store.dispatch('init2')
    }
  })
  .then(() => {
    if (token && !isEthing()) {
      return request.authentication
        .requestCurrentUser()
        .then(async (response) => {
          if (response.code === 200) {
            const userInfo = response.data

            await store.dispatch('setUserInfo', { ...store.state.userInfo, ...userInfo })

            // 对运营端账号进行拦截防止
            const t = userInfo.tenantList.find(t => t.select) || userInfo
            if (t.tenantId === '000000') {
              localStorage.removeItem('school-tagList')
              localStorage.removeItem('school-tag')
              window.location.href = `${location.origin}/admin/#/wel/index`
            }
          }
        })
    }
  })
  .then(async () => {
    Vue.directive('ali-oss', {
      /**
       *
       * @param {HTMLElement} el
       * @param {*} binding
       */
      async bind(el, binding) {
        if (el.src === `${location.origin}/`) {
          return
        }

        if (el instanceof HTMLImageElement || el instanceof HTMLVideoElement || el instanceof HTMLAudioElement) {
          if (binding.value === models.Enums.CourseSourceType.平台 || binding.value === '000000') { // 平台课程的链接
            utils.AliYunOSSPlatformHelper.parserUrl(el.src)
              .then((url) => {
                el.src = url
              })
          }
          else {
            utils.AliYunOSSHelper.parserUrl(el.src)
              .then((url) => {
                el.src = url
              })
          }
        }
      },
    })
    Vue.directive('avatar-load-error', {
      bind(el) {
        if (el instanceof HTMLImageElement) {
          el.onerror = () => {
            el.src = require('@/assets/image/avatar@2x.png')
          }
        }
      },
    })
    Vue.directive('course-post-load-error', {
      bind(el) {
        if (el instanceof HTMLImageElement) {
          el.onerror = () => {
            const error = document.createElement('div')
            const icon = document.createElement('i')
            icon.style = 'font-size: 60px;'
            icon.className = ['iconfont', 'icon-picture-error'].join(' ')
            error.style = 'display: flex; height: 100%; width: 100%; background: #f5f7fa; color: #c0c4cc; font-size: 30px; align-items: center; justify-content: center;'
            error.appendChild(icon)
            el.parentElement.appendChild(error)
            el.remove()
            // el.src = require('@/assets/mock/image/bg-card-course-poster4@2x.png')
          }
        }
      },
    })

    new Vue({
      router,
      store,
      render: h => h(App),
    }).$mount('#app')
  })
