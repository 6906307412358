var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "account-router-view" }, [
    _c("div", { staticClass: "content" }, [
      _vm._m(0),
      _c("div", { staticClass: "form" }, [
        _c("div", { staticClass: "form-content" }, [_c("router-view")], 1),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "intro" }, [
      _c("span", { staticClass: "intro-title" }, [_vm._v("事智通")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }