import { A as v, a as N, c as $ } from "./index-80db64c5.js";
import { C as h, a as g } from "./Enums-56ba9919.js";
const T = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "0"
];
function l(a, r) {
  return Math.floor(Math.random() * (r - a) + a);
}
function O(a, r) {
  const t = r || T;
  let e = "";
  for (let o = 0; o < a; o++) {
    const n = l(0, t.length);
    e = e + t[n];
  }
  return e;
}
function p() {
  const a = Math.floor(Math.random() * 256).toString(16).length < 2 ? `0${Math.floor(Math.random() * 256).toString(16)}` : Math.floor(Math.random() * 256).toString(16), r = Math.floor(Math.random() * 256).toString(16).length < 2 ? `0${Math.floor(Math.random() * 256).toString(16)}` : Math.floor(Math.random() * 256).toString(16), t = Math.floor(Math.random() * 256).toString(16).length < 2 ? `0${Math.floor(Math.random() * 256).toString(16)}` : Math.floor(Math.random() * 256).toString(16);
  return `#${a}${r}${t}`;
}
const I = {
  width: 120,
  height: 40,
  padding: 10,
  lineNumber: 2,
  dotNumber: 80,
  bezierLineNumber: 2,
  imgType: "image/png",
  encoderOptions: 1,
  length: 4,
  dict: T
};
function K(a) {
  const { length: r, dict: t, ...e } = {
    ...I,
    ...a
  }, o = O(r, t), n = document.createElement("canvas"), s = e.height, i = e.width, m = e.padding, y = s - m, b = i - m;
  n.height = s, n.width = i;
  const c = n.getContext("2d");
  if (!c)
    return console.error("can not get canvas context"), Promise.resolve(null);
  const d = o.length;
  for (let u = 0; u < d; u++) {
    const f = o[u], S = l(y / 2, y);
    c.font = `${S}px Georgia`, c.fillStyle = p(), c.shadowBlur = l(-3, 3), c.shadowColor = p();
    const C = l(-30, 30), M = m + u * b / d, w = s / 2 + S / 2;
    c.translate(M, w), c.rotate(C * Math.PI / 180), c.fillText(f, 0, 0, b / d), c.rotate(-C * Math.PI / 180), c.translate(-M, -w);
  }
  const _ = e.lineNumber;
  for (let u = 0; u < _; u++)
    c.strokeStyle = p(), c.beginPath(), c.moveTo(l(0, i), l(0, s)), c.lineTo(l(0, i), l(0, s)), c.stroke();
  const k = e.dotNumber;
  for (let u = 0; u < k; u++)
    c.fillStyle = p(), c.beginPath(), c.arc(l(0, i), l(0, s), 1, 0, 2 * Math.PI), c.fill();
  const x = e.bezierLineNumber;
  for (let u = 0; u < x; u++)
    c.fillStyle = p(), c.beginPath(), c.moveTo(l(0, i), l(0, s)), c.bezierCurveTo(
      l(0, i),
      l(0, s),
      l(0, i),
      l(0, s),
      l(0, i),
      l(0, s)
    ), c.stroke();
  return new Promise((u) => {
    n.toBlob((f) => u({ blob: f, code: o }), e.imgType, e.encoderOptions);
  });
}
const D = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  create: K
}, Symbol.toStringTag, { value: "Module" }));
function G(a) {
  const r = new OSS({
    region: a.region,
    endpoint: a.endpoint,
    accessKeyId: a.AccessKeyId,
    accessKeySecret: a.AccessKeySecret,
    stsToken: a.SecurityToken,
    bucket: a.bucket
  });
  return {
    parserData(t) {
      if (Array.isArray(t))
        return t.map((e) => this.parserData(e));
      if (typeof t == "object" && t !== void 0 && t !== null) {
        const e = Object.keys(t);
        for (let o = 0; o < e.length; o++) {
          const n = e[o], s = t[n];
          t[n] = this.parserData(s);
        }
        return t;
      } else
        return typeof t == "string" ? this.parserUrl(t) : t;
    },
    parserUrl(t) {
      if (/<img\s+[^>]*?src=["'](.*?)["'][^>]*>/g.test(t)) {
        if (r)
          return t.replace(/<img\s+[^>]*?src=["'](.*?)["'][^>]*>/g, (e, o) => {
            const n = t.includes("?") ? /^https?:\/\/.*\.aliyuncs.com\/(.*)\?/ig.exec(o) : /^https?:\/\/.*\.aliyuncs.com\/(.*)/ig.exec(o);
            return e.replace(o, n != null && n.at(1) ? r.signatureUrl(n.at(1)) : o);
          });
      } else {
        const e = t.includes("?") ? /^https?:\/\/.*\.aliyuncs.com\/(.*)\?/ig.exec(t) : /^https?:\/\/.*\.aliyuncs.com\/(.*)/ig.exec(t);
        if (r && (e != null && e.at(1)))
          return r.signatureUrl(e.at(1));
      }
      return t;
    },
    download(t, e) {
      const o = t.includes("?") ? /^https?:\/\/.*\.aliyuncs.com\/(.*)\?/ig.exec(t) : /^https?:\/\/.*\.aliyuncs.com\/(.*)/ig.exec(t), n = o == null ? void 0 : o.at(1);
      if (r && n) {
        const s = e ?? n.split("/").slice(-1).join(""), i = r.signatureUrl(n, {
          response: {
            "content-disposition": `attachment; filename=${encodeURIComponent(s)}`
          }
        }), m = document.createElement("a");
        return m.href = i, m.download = s, m.click(), !0;
      } else
        return !1;
    },
    downloadUrl(t, e) {
      const o = t.includes("?") ? /^https?:\/\/.*\.aliyuncs.com\/(.*)\?/ig.exec(t) : /^https?:\/\/.*\.aliyuncs.com\/(.*)/ig.exec(t), n = o == null ? void 0 : o.at(1);
      if (r && n) {
        const s = e ?? n.split("/").slice(-1).join("");
        return r.signatureUrl(n, {
          response: {
            "content-disposition": `attachment; filename=${encodeURIComponent(s)}`
          }
        });
      } else
        return !1;
    },
    async upload(t, e) {
      try {
        return await r.put(e, t), r.signatureUrl(e);
      } catch {
        return null;
      }
    },
    async exists(t) {
      try {
        return await r.head(t), !0;
      } catch (e) {
        return e.code === "NoSuchKey" ? !1 : null;
      }
    }
  };
}
function P(a) {
  let r = null;
  return a === h.线上课 ? r = g.线上课 : a === h.直播课 ? r = g.直播课 : a === h.培训任务 ? r = g.培训任务 : a === h.线下课 ? r = g.线下课 : a === h.图文课 && (r = g.图文课), r;
}
function U(a, r) {
  var i;
  const t = a.split(","), e = (i = t[0].match(/:(.\*?);/)) == null ? void 0 : i.at(1), o = atob(t[1]);
  let n = o.length;
  const s = new Uint8Array(n);
  for (; n--; )
    s[n] = o.charCodeAt(n);
  return new File([s], r, { type: e });
}
function A(a) {
  var s;
  const r = a.split(","), t = (s = r[0].match(/:(.\*?);/)) == null ? void 0 : s.at(1), e = atob(r[1]);
  let o = e.length;
  const n = new Uint8Array(o);
  for (; o--; )
    n[o] = e.charCodeAt(o);
  return new Blob([n], {
    type: t
  });
}
const j = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  base64ToBlob: A,
  base64ToFile: U
}, Symbol.toStringTag, { value: "Module" }));
class W {
  static GetJumpTimestampKey(r, t, e, o, n) {
    let s = `${t}_${e}_${r}_${o}`;
    return n && (s += `_${n}`), s;
  }
  /**
   * 设置视频继续播放信息
   * @param value 要存储的时间信息
   * @param uid 用户ID
   * @param vid 视频ID
   * @param t 机构ID
   * @param c 课程ID
   * @param p 培训ID
   */
  static SetJumpTimestamp(r, t, e, o, n, s) {
    const i = this.GetJumpTimestampKey(t, e, o, n, s);
    localStorage.setItem(i, r.toString());
  }
  /**
   * 获取设置的视频继续播放信息
   * @param uid 用户ID
   * @param vid 视频ID
   * @param t 机构ID
   * @param c 课程ID
   * @param p 培训ID
   */
  static GetJumpTimestamp(r, t, e, o, n) {
    const s = this.GetJumpTimestampKey(r, t, e, o, n), i = Number.parseFloat(localStorage.getItem(s) ?? "");
    return Number.isNaN(i) ? 0 : i;
  }
  /** 清空设置的视频继续播放信息 */
  static ClearJumpTimestamp(r, t, e, o, n) {
    const s = this.GetJumpTimestampKey(r, t, e, o, n);
    localStorage.removeItem(s);
  }
}
class z {
  static GetCourseWatchDurationKey(r, t, e, o, n) {
    let s = `course_watch_duration_${t}_${e}_${r}_${o}`;
    return n && (s += `_${n}`), s;
  }
  /**
   * 设置视频继续播放信息
   * @param value 要存储的时间信息
   * @param uid 用户ID
   * @param m 课件ID
   * @param t 机构ID
   * @param c 课程ID
   * @param p 培训ID
   */
  static SetCourseWatchDuration(r, t, e, o, n, s) {
    const i = this.GetCourseWatchDurationKey(t, e, o, n, s);
    localStorage.setItem(i, r.toString());
  }
  /**
   * 获取设置的视频继续播放信息
   * @param uid 用户ID
   * @param m 课件ID
   * @param t 机构ID
   * @param c 课程ID
   * @param p 培训ID
   */
  static GetCourseWatchDuration(r, t, e, o, n) {
    const s = this.GetCourseWatchDurationKey(r, t, e, o, n), i = Number.parseFloat(localStorage.getItem(s) ?? "");
    return Number.isNaN(i) ? 0 : i;
  }
  /** 清空设置的视频继续播放信息 */
  static ClearCourseWatchDuration(r, t, e, o, n) {
    const s = this.GetCourseWatchDurationKey(r, t, e, o, n);
    localStorage.removeItem(s);
  }
}
const B = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  AliYunOSSHelper: v,
  AliYunOSSPlatformHelper: N,
  CCVideoTools: W,
  Constants: $,
  CourseWatchDurationTools: z,
  base64: j,
  getCourseCommentType: P,
  getOSSClient: G,
  verifyCode: D
}, Symbol.toStringTag, { value: "Module" }));
export {
  W as C,
  G as a,
  j as b,
  z as c,
  P as g,
  B as i,
  D as v
};
