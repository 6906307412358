<script>
import { models, request } from '@qx-student-client/common'
import { mapState } from 'vuex'
import AccountCenterPopover from './AccountCenterPopover.vue'
import { RouteNames } from '@/router'

export default {
  name: 'Navigation',
  components: { AccountCenterPopover },
  data() {
    return {
      models,
      activeTab: undefined,
    }
  },
  computed: {
    ...mapState({
      /**
       * @returns {Undefinable<import("@/types/models/CurrentUser").CurrentUser>} 当前用户
       */
      userInfo: state => state.userInfo,
      /**
       * @returns {import("@/types/models/WebsiteProfile").WebsiteProfile} 配置信息
       */
      websiteProfile: state => state.websiteProfile,
      /**
       *@returns {{unread: number}} 消息中心共享变量
       */
      messageStore: state => state.messageStore,
    }),

    /**
     * @returns {(import("@qx-student-client/common").models.User & import("@qx-student-client/common").models.Authentication)['tenantList'][number]['roleConfig']} 当前公司
     */
    currentUserTenant() {
      return this.$store.getters.currentUserTenant
    },
  },
  watch: {
    $route() {
      this.triggerActiveTab()
    },
  },

  mounted() {
    this.triggerActiveTab()
  },
  methods: {
    triggerActiveTab() {
      if (!this.$route.name || this.$route.name === RouteNames.Home) {
        this.activeTab = 'home'
      }
      else if (this.$route.name === RouteNames.Course) {
        this.activeTab = 'courses'
      }
      else if (this.$route.name === RouteNames.Teacher) {
        this.activeTab = 'teachers'
      }
      else {
        this.activeTab = undefined
      }
    },
    onTabClick() {
      switch (this.activeTab) {
        case 'home':
          this.$router.push({ path: '/' })
          break
        case 'courses':
          this.$router.push({ name: RouteNames.Course })
          break
        case 'teachers':
          this.$router.push({ name: RouteNames.Teacher })
          break
      }
    },
    onStudentCenterClick() {
      if (
        this.currentUserTenant.questionFlag === models.Enums.Flag.开启
        || this.currentUserTenant.examFlag === models.Enums.Flag.开启
        || this.currentUserTenant.planFlag === models.Enums.Flag.开启
      ) {
        const active
          = this.currentUserTenant.planFlag === models.Enums.Flag.开启
            ? 'train'
            : this.currentUserTenant.examFlag === models.Enums.Flag.开启
              ? 'exam'
              : 'survey'
        this.$router.push({ name: RouteNames.StudentCenterTasks, params: { active } })
      }
      else {
        this.$router.push({ name: RouteNames.StudentCenterPersonalInfo })
      }
    },
    onManageEntryClick() {
      localStorage.removeItem('school-tagList')
      localStorage.removeItem('school-tag')
      window.open(`${location.origin}/admin/#/wel/index`, '_blank')
    },
    onEThingEntryClick() {
      request.authentication.loginGetShiShiMingTokenWithAccount(this.userInfo.account).then((response) => {
        if (response.code === 200) {
          if (this.$env.IS_PROD) {
            window.open(`https://ething.123eblog.com?client_id=${10002}&token=${response.data.token}`, '_blank')
          }
          else {
            window.open(`https://te-ething.123eblog.com?client_id=${10002}&token=${response.data.token}`, '_blank')
          }
        }
        else {
          this.$message.warning({ message: `前往事事明失败,${response.msg}`, offset: 100 })
        }
      })
    },
    onMessageCenterClick() {
      this.$router.push({ name: RouteNames.MessageCenter })
    },
    onHide() {
      this.$refs.popover.hide()
    },
    onShow() {
      this.$refs.popover.show()
    },
  },
}
</script>

<template>
  <div class="container">
    <div class="top-wrapper">
      <div class="top">
        <div class="app-icon-wrapper">
          <img
            v-if="websiteProfile && websiteProfile.enterprise && websiteProfile.enterprise.logo"
            v-ali-oss
            v-avatar-load-error
            class="app-icon"
            :src="websiteProfile.enterprise.logo"
            alt=""
          />
        </div>

        <div class="title">
          {{ websiteProfile.enterprise.company }}
        </div>

        <div v-if="userInfo && userInfo.thingsUserType === 1 && userInfo.thingStatus === 1" class="button-ething">
          <el-button style="border-radius: 10px" size="mini" type="primary" plain @click="onEThingEntryClick">
            事事明
          </el-button>
        </div>
        <div v-if="userInfo && userInfo.roleId && userInfo.roleId.length" class="button-manage">
          <el-button style="border-radius: 10px" size="mini" type="primary" plain @click="onManageEntryClick">
            管理端
          </el-button>
        </div>
      </div>
    </div>
    <div class="wrapper">
      <div class="navigate">
        <div class="tab">
          <el-tabs v-model="activeTab" @tab-click="onTabClick">
            <el-tab-pane label="首页" name="home" />
            <el-tab-pane label="课程中心" name="courses" />
            <el-tab-pane label="讲师风采" name="teachers" />
          </el-tabs>
        </div>
      </div>

      <div class="account">
        <div class="centers">
          <div class="message-center" :class="{ 'unread-message': messageStore.unread }" @click="onMessageCenterClick">
            <i class="el-icon-bell"></i>
          </div>
          <div class="account-center" @click="onStudentCenterClick">个人中心</div>
        </div>
        <div class="avatar-wrapper">
          <el-popover
            trigger="hover"
            :visible-arrow="false"
            popper-class="account-center-popover"
            transition="zoom-in-top"
            @hide="onHide"
            @show="onShow"
          >
            <AccountCenterPopover ref="popover" />
            <template v-if="!!userInfo" #reference>
              <img v-ali-oss v-avatar-load-error class="avatar" :src="userInfo.avatar" alt="" />
            </template>
          </el-popover>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.account-center-popover {
  margin-top: 15px !important;
  border-radius: 6px !important;
  box-shadow: none !important;
}
</style>

<style lang="scss" scoped>
.container {
  z-index: 9;
  width: 100%;
  border-bottom: 1px solid $primary-color-border;

  .top-wrapper {
    height: 40px;
    background: #f5faff;

    .top {
      display: flex;
      align-items: center;
      width: $view-content-min-width;
      height: 100%;
      margin: 0 auto;

      .app-icon-wrapper {
        align-self: center;
        width: 32px;
        height: 32px;

        .app-icon {
          align-self: center;
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }

      .title {
        margin: 0 auto 0 10px;
        color: #595959;
        font-weight: bold;
        font-size: 16px;
      }

      .button-manage {
        height: 30px;
        padding: 0 5px;
      }

      .button-manage,
      .button-ething {
        .el-button {
          &:active,
          &:focus {
            border-color: #b3d8ff !important;
          }
        }
      }
    }
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    width: $view-content-min-width;
    height: 60px;
    margin: 0 auto;

    .navigate {
      flex: 1;

      .tab {
        height: 100%;

        ::v-deep .el-tabs__header {
          height: 100%;
          margin: 0;
        }

        ::v-deep .el-tabs__nav-wrap {
          &::after {
            display: none;
          }

          .el-tabs__active-bar {
            // display: none;
          }
        }

        ::v-deep .el-tabs__item {
          height: 50px;
          color: #000;
          font-weight: bold;
          font-size: 16px;
          line-height: 60px;

          &.is-active {
            color: $primary-color;
            font-weight: bold;
          }
        }
      }
    }

    .account {
      display: flex;
      align-items: center;

      .centers {
        display: flex;
        align-items: center;
        margin: 0 40px;
        color: #000;

        .message-center {
          margin: 0;
          margin-right: 10px;
          font-size: 20px;

          &.unread-message {
            position: relative;

            &::after {
              position: absolute;
              top: 2px;
              right: -2px;
              width: 9px;
              height: 9px;
              background-color: #f00;
              border: 2px solid #fff;
              border-radius: 50%;
              content: '';
            }
          }
        }

        & > * {
          cursor: pointer;

          &:hover {
            color: $primary-color;
          }
        }
      }

      .avatar-wrapper {
        span {
          display: flex;
          align-items: center;
        }

        ::v-deep .el-popover__reference-wrapper {
          height: 36px;

          .avatar {
            width: 36px;
            height: 36px;
            border-radius: 40px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
