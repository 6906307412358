import { render, staticRenderFns } from "./IndexCourseRouterView.vue?vue&type=template&id=ae1f2a06"
import script from "./IndexCourseRouterView.vue?vue&type=script&lang=js"
export * from "./IndexCourseRouterView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_5zgqc6x6tzgo465wyrmw4yv374/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/qx-student-client_0COr/node_modules/.pnpm/vue-hot-reload-api@2.3.4/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ae1f2a06')) {
      api.createRecord('ae1f2a06', component.options)
    } else {
      api.reload('ae1f2a06', component.options)
    }
    module.hot.accept("./IndexCourseRouterView.vue?vue&type=template&id=ae1f2a06", function () {
      api.rerender('ae1f2a06', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/IndexCourseRouterView/IndexCourseRouterView.vue"
export default component.exports