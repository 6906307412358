var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !!_vm.userInfo
    ? _c("div", { staticClass: "account-center-popover-content" }, [
        _c("div", { staticClass: "head" }, [
          _c("img", {
            directives: [
              { name: "avatar-load-error", rawName: "v-avatar-load-error" },
              { name: "ali-oss", rawName: "v-ali-oss" },
            ],
            staticClass: "avatar",
            attrs: { src: _vm.userInfo.avatar, alt: "" },
          }),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "real-name" }, [
              _vm._v(_vm._s(_vm.userInfo.realName)),
            ]),
            _c(
              "div",
              { staticClass: "archives" },
              _vm._l(_vm.archives, function (item) {
                return _c("div", { key: item.label, staticClass: "field" }, [
                  _c("div", { staticClass: "value" }, [
                    _vm._v(_vm._s(item.value)),
                  ]),
                  _c("div", { staticClass: "label" }, [
                    _vm._v(_vm._s(item.label)),
                  ]),
                ])
              }),
              0
            ),
          ]),
        ]),
        _c("div", { staticClass: "list" }, [
          _c(
            "div",
            {
              staticClass: "list-item",
              on: { click: _vm.onStudentInfoCenterClick },
            },
            [
              _c("i", { staticClass: "iconfont icon-user-filled" }),
              _c("span", { staticClass: "text" }, [_vm._v("个人中心")]),
            ]
          ),
          _vm.currentUserTenant.planFlag === _vm.models.Enums.Flag.开启 ||
          _vm.currentUserTenant.examFlag === _vm.models.Enums.Flag.开启 ||
          _vm.currentUserTenant.questionFlag === _vm.models.Enums.Flag.开启
            ? _c(
                "div",
                {
                  staticClass: "list-item",
                  on: { click: _vm.onStudentTaskCenterClick },
                },
                [
                  _c("i", { staticClass: "iconfont icon-task-filled" }),
                  _c("span", { staticClass: "text" }, [_vm._v("我的任务")]),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "list-item",
              on: {
                mouseenter: _vm.onShowTenantListClick,
                mouseleave: _vm.onShowTenantListClick,
              },
            },
            [
              _c("i", { staticClass: "iconfont icon-switch-outlined" }),
              _c("span", { staticClass: "text" }, [_vm._v("切换公司")]),
              _vm.enterpriseSwitchVisible
                ? _c("div", { staticClass: "enterprise-switch-popover" }, [
                    _c(
                      "div",
                      { staticClass: "enterprise-list" },
                      _vm._l(_vm.userInfo.tenantList, function (tenant) {
                        return _c(
                          "div",
                          {
                            key: tenant.tenantId,
                            staticClass: "enterprise-list-item",
                            class: tenant.select ? "select" : "",
                            attrs: { title: tenant.tenantName },
                            on: {
                              click: function () {
                                return _vm.onSwitchTenant(tenant)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(tenant.tenantName) + " ")]
                        )
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
            ]
          ),
          _vm.currentUserTenant.certificateFlag === _vm.models.Enums.Flag.开启
            ? _c(
                "div",
                {
                  staticClass: "list-item",
                  on: { click: _vm.onCertificateClick },
                },
                [
                  _c("i", { staticClass: "iconfont icon-ca-filled" }),
                  _c("span", { staticClass: "text" }, [_vm._v("我的证书")]),
                ]
              )
            : _vm._e(),
        ]),
        _c("div", { staticClass: "logout" }, [
          _c("i", { staticClass: "iconfont icon-logout" }),
          _c("span", { staticClass: "text", on: { click: _vm.onLogout } }, [
            _vm._v("安全退出"),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }