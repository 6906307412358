<script>
import { mapActions, mapState } from 'vuex'
import { models, request } from '@qx-student-client/common'
import { RouteNames, resetRouter } from '@/router'

export default {
  name: 'AccountCenterPopover',
  data() {
    return {
      models,
      enterpriseSwitchVisible: false,
    }
  },
  computed: {
    ...mapState({
      /**
       * @returns {import("@/types/models/CurrentUser").CurrentUser} 用户信息
       */
      userInfo: state => state.userInfo,
    }),

    /**
     * @returns {(import("@qx-student-client/common").models.User & import("@qx-student-client/common").models.Authentication)['tenantList'][number]['roleConfig']} 当前公司
     */
    currentUserTenant() {
      return this.$store.getters.currentUserTenant
    },

    /**
     * @returns {{label: string; value: string}[]} 档案信息
     */
    archives() {
      const labels = {
        certificateCount: '证书',
        examCount: '考试',
        score: '积分',
        times: '课时',
      }
      if (this.currentUserTenant.certificateFlag === models.Enums.Flag.关闭) {
        delete labels.certificateCount
      }
      if (this.currentUserTenant.examFlag === models.Enums.Flag.关闭) {
        delete labels.examCount
      }
      return Object.keys(labels).map((key) => {
        return {
          label: labels[key],
          value: this.userInfo.stat[key],
        }
      })
    },
  },
  methods: {
    ...mapActions(['setUserInfo']),

    onLogout() {
      request.authentication.logout().then((response) => {
        if (response.code !== 200) {
          return
        }
        this.$message.success({ message: response.msg, offset: 100 })
        this.setUserInfo()
        this.$router.replace({ name: RouteNames.Login })
        resetRouter()
      })
    },

    onStudentTaskCenterClick() {
      this.$router.push({ name: RouteNames.StudentCenterTasks, params: { active: 'train' } })
    },

    onStudentInfoCenterClick() {
      if (
        this.currentUserTenant.questionFlag === models.Enums.Flag.开启
        || this.currentUserTenant.examFlag === models.Enums.Flag.开启
        || this.currentUserTenant.planFlag === models.Enums.Flag.开启
      ) {
        const active
          = this.currentUserTenant.planFlag === models.Enums.Flag.开启
            ? 'train'
            : this.currentUserTenant.examFlag === models.Enums.Flag.开启
              ? 'exam'
              : 'survey'
        this.$router.push({ name: RouteNames.StudentCenterTasks, params: { active } })
      }
      else {
        this.$router.push({ name: RouteNames.StudentCenterPersonalInfo })
      }
    },

    /**
     * @param {import("@qx-student-client/common").models.User['tenantList'][number]} tenant
     */
    onSwitchTenant(tenant) {
      if (tenant.select) {
        return
      }

      const loading = this.$loading({ text: '正在切换公司', lock: true })
      request.authentication.switchTenant(this.userInfo.account, tenant.tenantId).then((response) => {
        if (response.code === 200) {
          const authentication = response.data

          return request.authentication.requestCurrentUser().then(async (response) => {
            if (response.code === 200) {
              delete authentication.avatar
              await this.setUserInfo({ ...response.data, ...authentication })
              location.href = location.origin
              resetRouter()
            }
          })
        }
        else {
          // 如果切换机构失败则说明账号被停用或者删除，刷新一下机构列表
          return request.authentication.requestCurrentUser().then(async (response) => {
            if (response.code === 200) {
              await this.setUserInfo({ ...this.userInfo, ...response.data })
              location.href = location.origin
              resetRouter()
            }
          })
        }
      })
        .finally(() => {
          loading.close()
        })
    },

    async onShowTenantListClick() {
      if (!this.enterpriseSwitchVisible) {
        await request.authentication.requestCurrentUser().then(async (response) => {
          if (response.code === 200) {
            await this.setUserInfo({ ...this.userInfo, ...response.data })
          }
        })
      }
      this.enterpriseSwitchVisible = !this.enterpriseSwitchVisible
    },

    hide() {
      this.enterpriseSwitchVisible = false
    },

    show() {
      this.loading = true
      request.authentication
        .requestCurrentUser()
        .then(async (response) => {
          if (response.code === 200) {
            await this.setUserInfo({ ...this.userInfo, ...response.data })
          }
        })
        .finally(() => {
          this.loading = false
        })
    },

    onCertificateClick() {
      this.$router.push({ name: RouteNames.StudentCenterCert })
    },
  },
}
</script>

<template>
  <div v-if="!!userInfo" class="account-center-popover-content">
    <div class="head">
      <img v-avatar-load-error v-ali-oss class="avatar" :src="userInfo.avatar" alt="" />
      <div class="content">
        <div class="real-name">{{ userInfo.realName }}</div>
        <div class="archives">
          <div v-for="item of archives" :key="item.label" class="field">
            <div class="value">{{ item.value }}</div>
            <div class="label">{{ item.label }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="list">
      <div class="list-item" @click="onStudentInfoCenterClick">
        <i class="iconfont icon-user-filled"></i>
        <span class="text">个人中心</span>
      </div>
      <div
        v-if="
          currentUserTenant.planFlag === models.Enums.Flag.开启
            || currentUserTenant.examFlag === models.Enums.Flag.开启
            || currentUserTenant.questionFlag === models.Enums.Flag.开启
        "
        class="list-item"
        @click="onStudentTaskCenterClick"
      >
        <i class="iconfont icon-task-filled"></i>
        <span class="text">我的任务</span>
      </div>
      <div class="list-item" @mouseenter="onShowTenantListClick" @mouseleave="onShowTenantListClick">
        <i class="iconfont icon-switch-outlined"></i>
        <span class="text">切换公司</span>
        <div v-if="enterpriseSwitchVisible" class="enterprise-switch-popover">
          <div class="enterprise-list">
            <div
              v-for="tenant of userInfo.tenantList"
              :key="tenant.tenantId"
              :title="tenant.tenantName"
              class="enterprise-list-item"
              :class="tenant.select ? 'select' : ''"
              @click="() => onSwitchTenant(tenant)"
            >
              {{ tenant.tenantName }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="currentUserTenant.certificateFlag === models.Enums.Flag.开启"
        class="list-item"
        @click="onCertificateClick"
      >
        <i class="iconfont icon-ca-filled"></i>
        <span class="text">我的证书</span>
      </div>
    </div>

    <div class="logout">
      <i class="iconfont icon-logout"></i>
      <span class="text" @click="onLogout">安全退出</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.account-center-popover-content {
  width: 360px;
  padding: 0 10px;

  .head {
    display: flex;
    gap: 0 30px;
    align-items: center;
    padding: 10px 0 20px;
    border-bottom: 1px solid #eee;

    .avatar {
      width: 88px;
      height: 88px;
      object-fit: fill;
      border-radius: 80px;
    }

    .content {
      display: flex;
      flex: 1;
      flex-direction: column;
      gap: 15px 0;
      width: 100%;

      .real-name {
        margin-left: -2px;
        color: #000;
        font-size: 20px;
      }

      .archives {
        position: relative;
        left: -10px;
        display: flex;
        display: grid;
        grid-template-columns: repeat(4, minmax(60px, 60px));
        width: 100%;

        .field {
          text-align: center;

          .label {
            color: #666;
            font-size: 14px;
          }

          .value {
            color: #333;
            font-weight: 500;
            font-size: 20px;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .list {
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(2, auto);
    gap: 10px 20px;
    margin: 20px 0 10px;
    cursor: pointer;

    &-item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px 0;
      background: #f8fafc;

      .iconfont {
        margin-right: 10px;
      }
    }
  }

  .logout {
    display: flex;
    align-items: center;
    margin: 15px 0 5px;
    font-size: 15px;
    cursor: pointer;

    .iconfont {
      margin-right: 2px;
      font-size: 18px;
    }
  }

  .enterprise-switch-popover {
    position: absolute;
    top: 0;
    left: -200px;
    width: 200px;
    background: #f8fafc;
    border: 1px solid $primary-color-border;
    border-right: none;
    border-radius: 5px 0 0 5px;

    .enterprise-list {
      cursor: pointer;

      &-item {
        padding: 10px 20px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &.select {
          color: $primary-color;
        }
      }
    }
  }
}
</style>
