<script>
import Navigation from './components/Navigation.vue'

export default {
  name: 'IndexRouterView',
  components: { Navigation },
}
</script>

<template>
  <div class="index-router">
    <Navigation />

    <router-view class="router-view" />
  </div>
</template>

<style lang="scss" scoped>
.index-router {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .header {
    display: flex;
    width: 100%;
    height: 60px;
  }

  .router-view {
    flex: 1;
    height: 1px;
    padding-bottom: $view-padding-size;
    overflow: hidden auto;
    background-color: #F8F9FA;
  }
}
</style>
