if (!Array.prototype.at) {
  // eslint-disable-next-line no-extend-native
  Array.prototype.at = function (index) {
    if (index >= 0) {
      return this[index]
    }
    else {
      return this[this.length + index]
    }
  }
}
