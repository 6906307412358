<script>
import dayjs from 'dayjs'

/**
 * 考前提示
 */
export default {
  props: {
    /**
     * @type {import("vue").PropOptions<Nullable<import("@qx-student-client/common").models.ExamIntro>>}
     */
    exam: { type: Object },
  },
  mounted() {
  },
  methods: {
    onConfirm() {
      const isBefore = dayjs(this.exam.endTime).isBefore(Date.now())
      const isAfter = dayjs(this.exam.ststTime).isAfter(Date.now())

      if (isAfter) {
        this.$message.warning({ message: '考试未开始', offset: 100 })
        return
      }

      if (isBefore) {
        this.$message.warning({ message: '考试已结束', offset: 100 })
        return
      }

      // 不限次数
      if (this.exam.userWaitExamNums === -2) {
        this.$emit('confirm', this.exam)
      }
      else if (this.exam.userWaitExamNums === -1) { // 及格后终止
        this.$emit('confirm', this.exam)
        // this.$confirm('该考试限制次数为及格后终止, 请认真作答.', '考前提醒', {
        //   confirmButtonText: '我已知晓',
        // })
        //   .then((value) => {
        //     if (value === 'confirm') {
        //       this.$emit('confirm', this.exam)
        //     }
        //   })
        //   .catch(() => {})
      }
      else { // 最后一次进行提示
        if (this.exam.userWaitExamNums < 2) {
          this.$confirm('该考试当前为最后一次考试, 请认真作答.', '考前提醒', {
            confirmButtonText: '我已知晓',
          })
            .then((value) => {
              if (value === 'confirm') {
                this.$emit('confirm', this.exam)
              }
            })
            .catch(() => {})
        }
        else {
          this.$emit('confirm', this.exam)
        }
      }
    },
  },
}
</script>

<template>
  <div v-if="!!exam" class="exam-dialog-content">
    <div class="title">
      {{ exam.name }}
    </div>

    <div class="range">{{ exam.ststTime.slice(0, -3) }} ~ {{ exam.endTime.slice(0, -3) }}</div>

    <div class="fields">
      <div class="field">
        <div class="label">考试总分</div>
        <div class="value">
          <span class="big">{{ exam.paperScore }}</span>分
        </div>
      </div>
      <div class="field">
        <div class="label">及格分</div>
        <div class="value">
          <span class="big">{{ exam.passScore }}</span>分
        </div>
      </div>
      <div class="field">
        <div class="label">考试时长</div>
        <div class="value">
          <span class="big">{{ exam.examTime }}</span>分钟
        </div>
      </div>
      <div class="field">
        <div class="label">考试次数</div>
        <div class="value">
          <span class="big">{{ exam.frequencyNum }}</span>
        </div>
      </div>
      <div class="field">
        <div class="label">切屏次数</div>
        <div class="value">
          <span class="big">{{ exam.screenNums }}</span>
        </div>
      </div>
    </div>

    <div class="tips">进入考试后，计时结束时将自动交卷，退出后继续计时</div>

    <!-- <div class="smooth-signature">
      <canvas ref="smoothSignatureDom"></canvas>
    </div> -->

    <div class="footer">
      <el-button class="button" type="default" size="medium" plain @click="$emit('close')">取消</el-button>
      <el-button class="button" type="primary" size="medium" @click="onConfirm">开始考试</el-button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.exam-dialog-content {
  padding: 0 40px;
  font-size: 16px;

  .title {
    color: #333;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
  }

  .range {
    margin: 20px 0;
    color: #333;
    text-align: center;
  }

  .fields {
    display: flex;
    margin: 30px 0;
    background: #f5f7fa;

    .field {
      position: relative;
      display: flex;
      flex: 1;
      flex-direction: column;
      gap: 5px;
      align-items: center;
      justify-content: center;
      padding: 20px 0;

      .big {
        color: #333;
        font-weight: bold;
        font-size: 18px;
      }

      &::after {
        position: absolute;
        top: 35%;
        right: 0;
        width: 1px;
        height: 30%;
        background: #d1e3f2;
        content: '';
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }

  .tips {
    margin: 20px 0;
    color: $primary-color;
    text-align: center;
  }

  .smooth-signature {
    text-align: center;

    canvas {
      border: 2px dashed #ccc;
      cursor: crosshair;
    }
  }

  .footer {
    display: flex;
    justify-content: space-evenly;
    margin: 50px 0 20px;

    .button {
      width: 200px;
    }
  }
}
</style>
