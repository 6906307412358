import { SHA1, enc, lib } from 'crypto-js'

if (!(crypto.subtle || {}).decrypt) {
  // 防止在 http 环境下没有加密对象的物体
  crypto.subtle = {
    ...crypto.subtle,
    digest(algorithm, data) {
      // 计算 SHA1
      const hash = SHA1(lib.WordArray.create(data.slice(0, 16777216))).toString(enc.Hex)

      // 将十六进制字符串转换为字节数组
      const buffer = new Uint8Array(hash.length / 2)
      for (let i = 0; i < hash.length; i += 2) {
        buffer[i / 2] = Number.parseInt(hash.substr(i, 2), 16)
      }

      // 返回 ArrayBuffer
      return Promise.resolve(buffer.buffer)
    },
  }
}
