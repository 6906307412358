import Vue from 'vue'
import VueRouter from 'vue-router'
import { request } from '@qx-student-client/common'
import { Message } from 'element-ui'
import IndexRouterView from '@/views/IndexRouterView/IndexRouterView.vue'
import IndexCourseRouterView from '@/views/IndexCourseRouterView/IndexCourseRouterView.vue'
import IndexTeacherRouterView from '@/views/IndexTeacherRouterView/IndexTeacherRouterView.vue'
import AccountRouterView from '@/views/AccountRouterView/AccountRouterView.vue'
import AuthRouterView from '@/views/AuthRouterView/AuthRouterView.vue'
import StudentCenterRouterView from '@/views/StudentCenterRouterView/StudentCenterRouterView.vue'
import store from '@/store'

/**
 * 学员PC端页面路由名称
 */
export const RouteNames = {
  /**
   * 账号
   */
  Login: 'login',

  /**
   * 忘记密码
   */
  Forget: 'forget',

  /**
   * 企业注册
   */
  Enterprise: 'enterprise',

  /**
   * 第三方登录回调
   */
  ThirdParty: 'third-party',

  /**
   * 用户认证跳转页面
   */
  AuthBack: 'auth-back',

  /**
   * 首页
   */
  Home: 'home',

  /**
   * 课程中心
   */
  Course: 'course',

  /**
   * 课程详情
   */
  CourseDetails: 'course-details',

  /**
   * 讲师列表
   */
  Teacher: 'teacher',

  /**
   * 讲师申请
   */
  TeacherApply: 'apply',

  /**
   * 教师详情
   */
  TeacherDetails: 'teacher-details',

  /**
   * 课程播放大厅
   */
  CoursePlay: 'course-play',
  /** 师带徒任务播放大厅 */
  CoursePlayMAA: 'course-play-maa',
  /**
   * 课程资料播放页面
   */
  CourseMaterialView: 'course-material-view',

  /**
   * 直播课讲师直播页面
   */
  CourseLive: 'course-live',

  /**
   * 直播课播放大厅
   */
  CoursePlayLive: 'course-play-live',

  /**
   * 个人中心
   */
  StudentCenter: 'student-center',

  /**
   * 个人中心 - 我的任务
   */
  StudentCenterTasks: 'student-center-tasks',

  /**
   * 个人中心 - 我的证书
   */
  StudentCenterCert: 'student-center-cert',

  /**
   * 个人中心 - 我的积分
   */
  StudentCenterIntegral: 'student-center-integral',

  /**
   * 个人中心 - 个人信息
   */
  StudentCenterPersonalInfo: 'student-center-info',

  /**
   * 个人中心 - 师带徒
   */
  StudentTeacher: 'student-teacher',

  /**
   * 个人中心 - 个人档案
   */
  Archive: 'archive',

  /**
   * 个人中心 - 安全档案
   */
  StudentSecurityArchives: 'student-security-archives',

  /**
   * 个人中心 - 随机抽考
   */
  StudentSampleExam: 'student-sample-exam',

  /**
   * 个人中心 - 课程中心记录
   */
  StudentCourseLearnRecord: 'student-learn-record',

  /**
   * 个人中心 - 消息中心
   */
  MessageCenter: 'message-center',

  /**
   * 个人中心 - 问题反馈
   */
  IssuesFeedback: 'issues-feedback',

  /**
   * 考试数据页面
   */
  StudentExamData: 'student-exam-data',

  /**
   * 开始作答页面
   */
  ExamAnswering: 'exam-answering',

  /**
   * 作答详情页面
   */
  ExamAnswered: 'exam-answered',

  /**
   * 题库练习答题页面
   */
  PracticeAnswering: 'practice-answering',

  /**
   * 题库练习错题消除
   */
  PracticeAnsweringError: 'practice-answering-error',

  /**
   * 问卷调研
   */
  TasksSurveyAnswering: 'tasks-survey-answering',

  /**
   * 培训任务详情
   */
  TasksTrainingPlan: 'tasks-training-plan',

  /** 师带徒任务详情 */
  TasksMAA: 'task-maa',

  /**
   * 关于我们
   */
  AboutUS: 'about-us',
}

const originReplace = VueRouter.prototype.replace
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
const originBack = VueRouter.prototype.back

/**
 * @param {import("vue-router").Location} location
 */
VueRouter.prototype.replace = function (location) {
  return originReplace.call(this, location)
    .catch((error) => {
      // FIXED: 修复重复路由调转时的错误.
      if (error.name !== 'NavigationDuplicated') {
        console.error(error)
      }
      return error
    })
}

/**
 * @param {import("vue-router").Location} location
 */
VueRouter.prototype.push = function (location) {
  return originalPush.call(this, location)
    .catch((error) => {
      // FIXED: 修复重复路由调转时的错误.
      if (error.name !== 'NavigationDuplicated') {
        console.error(error)
      }
      return error
    })
}

/**
 * @param {import("vue-router").Location} location
 */
VueRouter.prototype.replace = function (location) {
  return originalReplace.call(this, location)
    .catch((error) => {
      // FIXED: 修复重复路由调转时的错误.
      if (error.name !== 'NavigationDuplicated') {
        console.error(error)
      }
      return error
    })
}

/**
 * 扩展原生路由返回方法，防止发生无法返回的问题
 */
VueRouter.prototype.back = function (...args) {
  if (store.state.routePrev) {
    originBack.call(this, ...args)
    // this.replace({ name: store.state.routePrev.name, query: store.state.routePrev.query, params: store.state.routePrev.params })
  }
  else {
    this.replace({ name: RouteNames.Home })
  }
}

Vue.use(VueRouter)

/**
 * 路由白名单
 */
export const whiteList = [RouteNames.AuthBack, RouteNames.Login, RouteNames.Forget, RouteNames.ThirdParty, RouteNames.Enterprise]

/**
 * 页面路由定义
 * @type {import("vue-router").RouteConfig[]}
 */
const routes = [
  {
    path: '/',
    component: IndexRouterView,
    children: [
      {
        path: '',
        component: () =>
          import(/* webpackChunkName: "Home" */ '@/views/IndexHomeView/IndexHomeView.vue'),
      },
      {
        path: RouteNames.Home,
        name: RouteNames.Home,
        component: () =>
          import(/* webpackChunkName: "Home" */ '@/views/IndexHomeView/IndexHomeView.vue'),
      },
      {
        path: RouteNames.Course,
        component: IndexCourseRouterView,
        children: [
          {
            path: '',
            name: RouteNames.Course,
            component: () =>
              import(/* webpackChunkName: "Course" */ '@/views/IndexCourseListView/IndexCourseListView.vue'),
          },
          {
            path: ':id',
            name: RouteNames.CourseDetails,
            component: () =>
              import(/* webpackChunkName: "Course" */ '@/views/IndexCourseDetailsView/IndexCourseDetailsView.vue'),
          },
        ],
      },
      {
        path: RouteNames.Teacher,
        component: IndexTeacherRouterView,
        children: [
          {
            path: '',
            name: RouteNames.Teacher,
            component: () =>
              import(/* webpackChunkName: "Teacher" */ '@/views/IndexTeacherListView/IndexTeacherListView.vue'),
          },
          {
            path: RouteNames.TeacherApply,
            name: RouteNames.TeacherApply,
            component: () =>
              import(/* webpackChunkName: "Teacher" */ '@/views/IndexTeacherApplyView/IndexTeacherApplyView.vue'),
          },
          {
            path: ':id',
            name: RouteNames.TeacherDetails,
            component: () =>
              import(/* webpackChunkName: "Teacher" */ '@/views/IndexTeacherDetailsView/IndexTeacherDetailsView.vue'),
          },
        ],
      },
      {
        path: 'student-center',
        name: RouteNames.StudentCenter,
        component: StudentCenterRouterView,
        children: [
          {
            path: 'cert',
            name: RouteNames.StudentCenterCert,
            component: () => import(/* webpackChunkName: "StudentCenter" */ '@/views/StudentCenterCertView/StudentCenterCertView.vue'),
          },
          {
            path: 'integral',
            name: RouteNames.StudentCenterIntegral,
            component: () => import(/* webpackChunkName: "StudentCenter" */ '@/views/StudentCenterIntegralView/StudentCenterIntegralView.vue'),
          },
          {
            path: 'info',
            name: RouteNames.StudentCenterPersonalInfo,
            component: () => import(/* webpackChunkName: "StudentCenter" */ '@/views/StudentCenterInfoView/StudentCenterInfoView.vue'),
          },
          {
            path: RouteNames.StudentSecurityArchives,
            name: RouteNames.StudentSecurityArchives,
            component: () => import(/* webpackChunkName: "StudentCenter" */ '@/views/StudentSecurityArchivesView/StudentSecurityArchivesView.vue'),
          },
          {
            path: RouteNames.StudentSampleExam,
            name: RouteNames.StudentSampleExam,
            component: () => import(/* webpackChunkName: "StudentCenter" */ '@/views/StudentSampleExamView/StudentSampleExamView.vue'),
          },
          {
            path: RouteNames.MessageCenter,
            name: RouteNames.MessageCenter,
            component: () => import(/* webpackChunkName: "StudentCenter" */ '@/views/MessageCenterView/MessageCenterView.vue'),
          },
          {
            path: RouteNames.StudentCourseLearnRecord,
            name: RouteNames.StudentCourseLearnRecord,
            component: () => import(/* webpackChunkName: "StudentCenter" */ '@/views/CourseLearnRecordView/CourseLearnRecordView.vue'),
          },
          {
            path: RouteNames.Archive,
            name: RouteNames.Archive,
            component: () => import(/* webpackChunkName: "StudentCenter" */ '@/views/StudentArchivesView/StudentArchivesView.vue'),
          },
          {
            path: RouteNames.IssuesFeedback,
            name: RouteNames.IssuesFeedback,
            component: () => import(/* webpackChunkName: "StudentCenter" */ '@/views/StudentCenterRouterView/fragments/IssuesFeedback.vue'),
          },
          {
            path: RouteNames.StudentTeacher,
            name: RouteNames.StudentTeacher,
            component: () => import(/* webpackChunkName: "StudentCenter" */ '@/views/MasterApprentice/MasterApprentice.vue'),
          },
          {
            path: RouteNames.AboutUS,
            name: RouteNames.AboutUS,
            component: () => import(/* webpackChunkName: "StudentCenter" */ '@/views/AboutUS/AboutUS.vue'),
          },
          {
            path: ':active',
            name: RouteNames.StudentCenterTasks,
            component: () => import(/* webpackChunkName: "StudentCenter" */ '@/views/StudentCenterTasksView/StudentCenterTasksView.vue'),
          },
        ],
      },
      {
        path: 'student-exam-data',
        name: RouteNames.StudentExamData,
        component: () => import(/* webpackChunkName: "StudentExam" */ '@/views/StudentExamDataView/StudentExamDataView.vue'),
      },
      {
        path: RouteNames.TasksSurveyAnswering,
        name: RouteNames.TasksSurveyAnswering,
        component: () => import(/* webpackChunkName: "StudentSurvey" */ '@/views/TasksSurveyAnsweringView/TasksSurveyAnsweringView.vue'),
      },
      {
        path: `${RouteNames.TasksTrainingPlan}/:id`,
        name: RouteNames.TasksTrainingPlan,
        component: () => import(/* webpackChunkName: "StudentTraining" */ '@/views/TasksTrainingPlanView/TasksTrainingPlanView.vue'),
      },
      {
        path: `${RouteNames.TasksMAA}`,
        name: RouteNames.TasksMAA,
        component: () => import(/* webpackChunkName: "TasksMAA" */ '@/views/TasksMAA/TasksMAA.vue'),
      },
    ],
  },
  {
    path: '/account',
    component: AccountRouterView,
    children: [
      {
        path: '',
        component: () =>
          import(
            /* webpackChunkName: "account" */ '@/views/AccountLoginView/AccountLoginView.vue'
          ),
      },
      {
        path: RouteNames.Login,
        name: RouteNames.Login,
        component: () =>
          import(
            /* webpackChunkName: "account" */ '@/views/AccountLoginView/AccountLoginView.vue'
          ),
      },
      {
        path: RouteNames.Forget,
        name: RouteNames.Forget,
        component: () =>
          import(
            /* webpackChunkName: "account" */ '@/views/AccountForgetView/AccountForgetView.vue'
          ),
      },
      {
        path: RouteNames.Enterprise,
        name: RouteNames.Enterprise,
        component: () =>
          import(
            /* webpackChunkName: "account" */ '@/views/AccountEnterpriseView/AccountEnterpriseView.vue'
          ),
      },
      {
        path: RouteNames.ThirdParty,
        name: RouteNames.ThirdParty,
        component: () =>
          import(
            /* webpackChunkName: "account" */ '@/views/AccountThirdPartyView/AccountThirdPartyView.vue'
          ),
      },
    ],
  },
  {
    path: '/oauth',
    component: AuthRouterView,
    children: [
      {
        path: '',
        component: () =>
          import(/* webpackChunkName: "auth" */ '@/views/AuthBackView/AuthBackView.vue'),
      },
      {
        path: 'back', // WARN: 此处为后端提供回调页面, 路径名称不能擅自修改
        name: RouteNames.AuthBack,
        component: () =>
          import(/* webpackChunkName: "auth" */ '@/views/AuthBackView/AuthBackView.vue'),
      },
    ],
  },
  {
    path: '/course-play',
    name: RouteNames.CoursePlay,
    component: () =>
      import(/* webpackChunkName: "course-play" */ '@/views/CoursePlayView/CoursePlayView.vue'),
  },
  {
    path: '/course-play-maa',
    name: RouteNames.CoursePlayMAA,
    component: () =>
      import(/* webpackChunkName: "course-play" */ '@/views/CoursePlayViewMAA/CoursePlayViewMAA.vue'),
  },
  {
    path: '/course-material-view',
    name: RouteNames.CourseMaterialView,
    component: () => import(/* webpackChunkName: "course-play" */ '@/views/CourseMaterialView/CourseMaterialView.vue'),
  },
  {
    path: '/course-live',
    name: RouteNames.CourseLive,
    component: () =>
      import(/* webpackChunkName: "course-play" */ '@/views/CourseLiveView/CourseLiveView.vue'),
  },
  {
    path: '/course-play-live',
    name: RouteNames.CoursePlayLive,
    component: () =>
      import(/* webpackChunkName: "course-play" */ '@/views/CoursePlayLiveView/CoursePlayLiveView.vue'),
  },
  {
    path: '/exam-answering',
    name: RouteNames.ExamAnswering,
    component: () =>
      import(/* webpackChunkName: "StudentExam" */ '@/views/TasksExamAnsweringView/TasksExamAnsweringView.vue'),
  },
  {
    path: '/exam-answered',
    name: RouteNames.ExamAnswered,
    component: () =>
      import(/* webpackChunkName: "StudentExam" */ '@/views/TasksExamAnsweredView/TasksExamAnsweredView.vue'),
  },
  {
    path: `/${RouteNames.PracticeAnswering}/:id/:record`,
    name: RouteNames.PracticeAnswering,
    component: () => import(/* webpackChunkName: "StudentExam" */ '@/views/TasksPracticeAnsweringView/TasksPracticeAnsweringView.vue'),
  },
  {
    path: `/${RouteNames.PracticeAnsweringError}/:id/:record`,
    name: RouteNames.PracticeAnsweringError,
    component: () => import(/* webpackChunkName: "StudentExam" */ '@/views/TasksPracticeAnsweringErrorView/TasksPracticeAnsweringErrorView.vue'),
  },
]

function createRouter() {
  return new VueRouter({
    mode: 'hash',
    routes,
  })
}
const router = createRouter()

router.beforeEach(async (to, from, next) => {
  const userInfo = store.state.userInfo
  const hasUserInfo = userInfo && Object.keys(userInfo).length

  const { name, query, params } = from
  if (name) {
    store.dispatch('setRoutePrev', { name, query, params })
  }

  if (to.name === RouteNames.Login && hasUserInfo) {
    next({ name: RouteNames.Home })
  }
  else if (whiteList.includes(to.name ?? '')) {
    next()
  }
  else {
    /**
     * @type {{token: string; tokenTimeout: number; selfToken: string}}
     */
    const query = Object.fromEntries(new URLSearchParams(location.search).entries())

    if (hasUserInfo && !query.selfToken && !query.token) {
      next()
    }
    else {
      // 判断是否从事事明跳转过来的
      if (query.token) {
        // window.postMessage(query.token)
        try {
          // 如果不同源将无法访问导致报错，那么就可以判定是从事事明跳转过来的。走 Catch 部分代码
          if (window.parent.opener.origin === window.origin) {
            window.parent.opener.postMessage({ token: query.token }, { targetOrigin: window.location.origin })
          }
        }
        catch (ignore) {
          try {
            const response = await request.authentication.loginGetShiShiMingToken(query.token)
            if (response.code === 200) {
              // 重新加载已刷新账号信息
              try {
                await store.dispatch('setUserInfo', response.data)
                window.history.replaceState({}, '', window.location.pathname)
                window.location.reload()
              }
              catch (e) {
                console.error(e)
              }
              return
            }
          }
          catch (e) {
            Message.error({ message: e.message, offset: 100 })
          }
        }
      }
      // 自己的页面
      else if (query.selfToken) {
        try {
          const response = await request.authentication.loginGetShiShiMingToken(query.selfToken)
          if (response.code === 200) {
            // 重新加载已刷新账号信息
            await store.dispatch('setUserInfo', response.data)
            window.history.replaceState({}, '', window.location.pathname)
            window.location.reload()
            return
          }
          // Message.warning({ message: response.msg, offset: 100 })
        }
        catch (e) {
          Message.error({ message: e.message, offset: 100 })
        }
      }
      if (window.location.search) {
        window.history.replaceState({}, '', window.location.pathname)
      }
      next({ name: RouteNames.Login })
    }
  }
})

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // 重置路由
}

export default router
