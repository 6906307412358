var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "root" } },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "考前提示",
            visible: _vm.examDialog.visible,
            width: "800px",
            "before-close": _vm.onExamTipClose,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.examDialog, "visible", $event)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return undefined
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("ExamPreInstructions", {
            attrs: { exam: _vm.examDialog.exam },
            on: { close: _vm.onExamTipClose, confirm: _vm.onExamTipConfirm },
          }),
        ],
        1
      ),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }