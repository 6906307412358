import { render, staticRenderFns } from "./IndexTeacherRouterView.vue?vue&type=template&id=0a31d605"
import script from "./IndexTeacherRouterView.vue?vue&type=script&lang=js"
export * from "./IndexTeacherRouterView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_b236zbqlyliw4qpn74y353xlqm/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/qx-student-client_0COr/node_modules/.pnpm/vue-hot-reload-api@2.3.4/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0a31d605')) {
      api.createRecord('0a31d605', component.options)
    } else {
      api.reload('0a31d605', component.options)
    }
    module.hot.accept("./IndexTeacherRouterView.vue?vue&type=template&id=0a31d605", function () {
      api.rerender('0a31d605', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/IndexTeacherRouterView/IndexTeacherRouterView.vue"
export default component.exports