import { render, staticRenderFns } from "./AccountCenterPopover.vue?vue&type=template&id=3e5ba103&scoped=true"
import script from "./AccountCenterPopover.vue?vue&type=script&lang=js"
export * from "./AccountCenterPopover.vue?vue&type=script&lang=js"
import style0 from "./AccountCenterPopover.vue?vue&type=style&index=0&id=3e5ba103&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.11.1_5zgqc6x6tzgo465wyrmw4yv374/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e5ba103",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/qx-student-client_0COr/node_modules/.pnpm/vue-hot-reload-api@2.3.4/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3e5ba103')) {
      api.createRecord('3e5ba103', component.options)
    } else {
      api.reload('3e5ba103', component.options)
    }
    module.hot.accept("./AccountCenterPopover.vue?vue&type=template&id=3e5ba103&scoped=true", function () {
      api.rerender('3e5ba103', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/IndexRouterView/components/AccountCenterPopover.vue"
export default component.exports