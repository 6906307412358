<script>
import { mapState } from 'vuex'
import NavigateSideBar from './components/NavigateSideBar/NavigateSideBar.vue'

export default {
  components: { NavigateSideBar },
  computed: {
    ...mapState({
      /**
       * @returns {import("@/types/models/CurrentUser").CurrentUser} 当前用户信息
       */
      userInfo: state => state.userInfo,
    }),
  },
}
</script>

<template>
  <div class="student-center-router-view">
    <div class="content">
      <NavigateSideBar />

      <router-view class="router-view" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.student-center-router-view {
  flex: 1;
  width: 100%;
  height: 1px;
  overflow: hidden auto;

  .content {
    display: flex;
    gap: 20px;
    align-items: flex-start;
    width: $view-content-min-width;
    min-height: 100%;
    margin: 0 auto;
    padding: 30px 0;

    .router-view {
      flex: 1;
    }
  }
}
</style>
