import Vue from 'vue'
import Vuex from 'vuex'
import { request, utils } from '@qx-student-client/common'
import createVuexAlong from 'vuex-along'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    /**
     * 当前登录用户信息
     * @type {import("@qx-student-client/common").models.User & import("@qx-student-client/common").models.Authentication}
     */
    userInfo: undefined,

    /**
     * 本机构网站配置信息
     * @type {import("@/types/models/WebsiteProfile").WebsiteProfile}
     */
    websiteProfile: {},

    /**
     * 讲师等级列表
     * @type {import("@qx-student-client/common").models.TeacherLevel[]}
     */
    teacherLevel: [],

    /**
     * 本机构cc视频配置信息
     * @type {{userid: string}}
     */
    ccVideoConfig: {},

    /**
     * 运营端cc视频和oss配置信息
     * @type {import("@qx-student-client/common").request.websiteProfile.PlatformConfiguration}
     */
    platformConfiguration: {},

    /**
     * 机构积分规则
     * @type {import("@qx-student-client/common").request.integral.CurrentUserIntegralRuleLisItem[]}
     */
    integralRules: [],

    /**
     * 上一个路由信息
     * @type {import("vue-router").Route}
     */
    routePrev: undefined,

    /**
     * 消息中心共享变量
     * @type {{unread?: number}}
     */
    messageStore: {
      /**
       * 未读消息数
       */
      unread: 0,
    },
  },
  getters: {
    /**
     * @returns {(import("@qx-student-client/common").models.User & import("@qx-student-client/common").models.Authentication)['tenantList'][number]['roleConfig']} 当前公司
     */
    currentUserTenant(state) {
      if (state.userInfo && state.userInfo.tenantList && state.userInfo.tenantList.length) {
        const t = state.userInfo.tenantList.find(t => t.select)
        return t ? t.roleConfig : {}
      }
      return {}
    },
  },
  mutations: {
    SET_USER_INFO: (state, payload) => {
      state.userInfo = payload

      // 管理端需要用到的信息
      if (payload) {
        localStorage.setItem(utils.Constants.STORAGE_USERINFO_KEY, JSON.stringify({
          dataType: 'object',
          datetime: Date.now(),
          content: payload,
        }))
      }
      else {
        localStorage.removeItem(utils.Constants.STORAGE_USERINFO_KEY)
      }
    },
    SET_WEB_SITE_PROFILE: (state, payload) => {
      state.websiteProfile = payload
    },
    SET_TEACHER_LEVEL: (state, payload) => {
      state.teacherLevel = payload
    },
    SET_CC_VIDEO_CONFIG: (state, payload) => {
      state.ccVideoConfig = payload
    },
    SET_PLATFORM_CONFIGURATION: (state, payload) => {
      state.platformConfiguration = payload
    },
    SET_INTEGRAL_RULES: (state, payload) => {
      state.integralRules = payload
    },
    SET_ROUTE_PREV: (state, payload) => {
      state.routePrev = payload
    },
    SET_MESSAGE_STORE: (state, payload) => {
      state.messageStore = payload
    },
  },
  actions: {
    async setUserInfo({ commit }, payload) {
      if (payload && Object.keys(payload).length) {
        const { code: code1, data: masterApprentice } = await request.masterAndApprentice.getCurrentStudentIdentity()
        if (code1 === 200) {
          payload = { ...payload, masterApprentice }
        }
      }

      commit('SET_USER_INFO', payload)
    },
    /**
     * 不需要使用Token就可以获取到的全局数据
     * @param {*} param0
     */
    async init1({ commit }) {
      const { code: code1, data: userAgreement } = await request.websiteProfile.requestUserAgreement()

      const websiteProfile = this.state.websiteProfile
      if (code1 === 200) {
        websiteProfile.userAgreement = userAgreement.privacy
      }

      commit('SET_WEB_SITE_PROFILE', websiteProfile)
    },
    /**
     * 需要使用token才可以获取到的全局数据
     * @param {*} param0
     */
    async init2({ commit, dispatch }) {
      const { code: code1, data: enterprise } = await request.websiteProfile.requestEnterpriseInfo()
      const { code: code3, data: teacherLevel } = await request.teacher.requestTeacherLevel()
      const { code: code4, data: bannerList } = await request.websiteProfile.requestBannerList()
      const { code: code5, data: ccVideoConfig } = await request.websiteProfile.requestCCVideoConfig()
      const { code: code6, data: platformConfiguration } = await request.websiteProfile.requestPlatformConfiguration()
      const { code: code7, data: integralRules } = await request.integral.getCurrentUserIntegralRuleList()

      const websiteProfile = this.state.websiteProfile
      commit('SET_WEB_SITE_PROFILE', {
        ...websiteProfile,
        bannerList: code4 === 200 ? bannerList : [],
        enterprise: code1 === 200 ? enterprise : {},
      })

      if (code3 === 200) {
        commit('SET_TEACHER_LEVEL', teacherLevel)
      }
      if (code5 === 200) {
        commit('SET_CC_VIDEO_CONFIG', ccVideoConfig)
      }
      if (code6 === 200) {
        commit('SET_PLATFORM_CONFIGURATION', platformConfiguration)
      }
      if (code7 === 200) {
        commit('SET_INTEGRAL_RULES', integralRules.records)
      }

      dispatch('updateMessageStoreUnread')
    },
    setRoutePrev({ commit }, payload) {
      commit('SET_ROUTE_PREV', payload)
    },
    setMessageStore({ commit }, payload) {
      commit('SET_MESSAGE_STORE', payload)
    },
    async updateMessageStoreUnread({ commit }) {
      return request.message.getUnreadMessageCount()
        .then((response) => {
          if (response.code === 200) {
            const payload = { ...this.state.messageStore, unread: response.data.unReadNum }
            commit('SET_MESSAGE_STORE', payload)
          }
        })
    },
  },
  modules: {},
  plugins: [
    createVuexAlong({
      name: utils.Constants.STORAGE_KEY,
      local: {
        list: ['userInfo'],
        isFilter: false,
      },
    }),
  ],
})
