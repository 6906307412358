var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "top-wrapper" }, [
      _c("div", { staticClass: "top" }, [
        _c("div", { staticClass: "app-icon-wrapper" }, [
          _vm.websiteProfile &&
          _vm.websiteProfile.enterprise &&
          _vm.websiteProfile.enterprise.logo
            ? _c("img", {
                directives: [
                  { name: "ali-oss", rawName: "v-ali-oss" },
                  { name: "avatar-load-error", rawName: "v-avatar-load-error" },
                ],
                staticClass: "app-icon",
                attrs: { src: _vm.websiteProfile.enterprise.logo, alt: "" },
              })
            : _vm._e(),
        ]),
        _c("div", { staticClass: "title" }, [
          _vm._v(" " + _vm._s(_vm.websiteProfile.enterprise.company) + " "),
        ]),
        _vm.userInfo &&
        _vm.userInfo.thingsUserType === 1 &&
        _vm.userInfo.thingStatus === 1
          ? _c(
              "div",
              { staticClass: "button-ething" },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { "border-radius": "10px" },
                    attrs: { size: "mini", type: "primary", plain: "" },
                    on: { click: _vm.onEThingEntryClick },
                  },
                  [_vm._v(" 事事明 ")]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.userInfo && _vm.userInfo.roleId && _vm.userInfo.roleId.length
          ? _c(
              "div",
              { staticClass: "button-manage" },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { "border-radius": "10px" },
                    attrs: { size: "mini", type: "primary", plain: "" },
                    on: { click: _vm.onManageEntryClick },
                  },
                  [_vm._v(" 管理端 ")]
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]),
    _c("div", { staticClass: "wrapper" }, [
      _c("div", { staticClass: "navigate" }, [
        _c(
          "div",
          { staticClass: "tab" },
          [
            _c(
              "el-tabs",
              {
                on: { "tab-click": _vm.onTabClick },
                model: {
                  value: _vm.activeTab,
                  callback: function ($$v) {
                    _vm.activeTab = $$v
                  },
                  expression: "activeTab",
                },
              },
              [
                _c("el-tab-pane", { attrs: { label: "首页", name: "home" } }),
                _c("el-tab-pane", {
                  attrs: { label: "课程中心", name: "courses" },
                }),
                _c("el-tab-pane", {
                  attrs: { label: "讲师风采", name: "teachers" },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "account" }, [
        _c("div", { staticClass: "centers" }, [
          _c(
            "div",
            {
              staticClass: "message-center",
              class: { "unread-message": _vm.messageStore.unread },
              on: { click: _vm.onMessageCenterClick },
            },
            [_c("i", { staticClass: "el-icon-bell" })]
          ),
          _c(
            "div",
            {
              staticClass: "account-center",
              on: { click: _vm.onStudentCenterClick },
            },
            [_vm._v("个人中心")]
          ),
        ]),
        _c(
          "div",
          { staticClass: "avatar-wrapper" },
          [
            _c(
              "el-popover",
              {
                attrs: {
                  trigger: "hover",
                  "visible-arrow": false,
                  "popper-class": "account-center-popover",
                  transition: "zoom-in-top",
                },
                on: { hide: _vm.onHide, show: _vm.onShow },
                scopedSlots: _vm._u(
                  [
                    !!_vm.userInfo
                      ? {
                          key: "reference",
                          fn: function () {
                            return [
                              _c("img", {
                                directives: [
                                  { name: "ali-oss", rawName: "v-ali-oss" },
                                  {
                                    name: "avatar-load-error",
                                    rawName: "v-avatar-load-error",
                                  },
                                ],
                                staticClass: "avatar",
                                attrs: { src: _vm.userInfo.avatar, alt: "" },
                              }),
                            ]
                          },
                          proxy: true,
                        }
                      : null,
                  ],
                  null,
                  true
                ),
              },
              [_c("AccountCenterPopover", { ref: "popover" })],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }