import { getSelectorsByUserAgent, isMobile } from 'react-device-detect'

const isEBlog = navigator.userAgent.toLowerCase().includes('eblog')

if (isMobile || isEBlog) {
  if (process.env.NODE_ENV === 'development') {
    location.href = `${location.origin.replace('8080', '8081')}/h5/#/pages/index/index${location.search}`
  }
  else {
    location.href = `${location.origin}/h5/#/pages/index/index${location.search}`
  }
}
window.addEventListener('resize', () => {
  const { isMobile } = getSelectorsByUserAgent(navigator.userAgent)
  if (isMobile || isEBlog) {
    if (process.env.NODE_ENV === 'development') {
      location.href = `${location.origin.replace('8080', '8081')}/h5/#/pages/index/index${location.search}`
    }
    else {
      location.href = `${location.origin}/h5/#/pages/index/index${location.search}`
    }
  }
})
