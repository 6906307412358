<script>
import { models } from '@qx-student-client/common'
import { mapState } from 'vuex'
import StudentArchives from '../StudentArchives/StudentArchives.vue'
import { RouteNames } from '@/router'

export default {
  components: { StudentArchives },
  data() {
    return {
      /**
       * @type {{key: string; icon0: string; icon1: string; title: string; active: boolean; name: string; params: {[key: string]: any}; hover: boolean; visible: boolean}[]}
       */
      navigates: [],
    }
  },
  computed: {
    ...mapState({
      /**
       * @returns {import("@/types/models/CurrentUser").CurrentUser} 当前用户
       */
      userInfo: state => state.userInfo,
    }),
    /**
     * @returns {(import("@qx-student-client/common").models.User & import("@qx-student-client/common").models.Authentication)['tenantList'][number]['roleConfig']} 当前公司
     */
    currentUserTenant() {
      return this.$store.getters.currentUserTenant
    },
  },
  watch: {
    /**
     * 监听路由变化设置导航栏高亮
     */
    '$route.name': function (name) {
      this.navigates = this.navigates.map(navigate => ({
        ...navigate,
        active: navigate.name === name,
      }))
    },
    /**
     * 监听当前公司变动
     */
    currentUserTenant() {
      this.prepareNavigateData()
    },
  },
  created() {
    this.prepareNavigateData()
  },

  methods: {
    /**
     * 准备导航栏数据
     */
    async prepareNavigateData() {
      const name = this.$route.name

      this.navigates = [
        {
          key: 'task',
          icon0: '',
          icon1: '',
          title: '我的任务',
          active: true,
          hover: false,
          name: RouteNames.StudentCenterTasks,
          params: { active: 'train' },
          visible:
            this.currentUserTenant.planFlag === models.Enums.Flag.开启
            || this.currentUserTenant.examFlag === models.Enums.Flag.开启
            || this.currentUserTenant.questionFlag === models.Enums.Flag.开启,
        },
        // {
        //   key: 'sample-exam',
        //   icon0: '',
        //   icon1: '',
        //   title: '随机抽考',
        //   active: false,
        //   name: RouteNames.StudentSampleExam,
        // },
        // { key: 'message', icon0: '', icon1: '', title: '消息中心', active: false, name: RouteNames.MessageCenter },
        {
          key: 'certificate',
          icon0: '',
          icon1: '',
          title: '我的证书',
          active: false,
          name: RouteNames.StudentCenterCert,
          visible: this.currentUserTenant.certificateFlag === models.Enums.Flag.开启,
        },
        {
          key: 'integral',
          icon0: '',
          icon1: '',
          title: '我的积分',
          active: false,
          name: RouteNames.StudentCenterIntegral,
          visible: true,
        },
        {
          key: 'learn',
          icon0: '',
          icon1: '',
          title: '学习记录',
          active: false,
          visible: true,
          name: RouteNames.StudentCourseLearnRecord,
        },
        {
          key: 'personal',
          icon0: '',
          icon1: '',
          title: '个人信息',
          active: false,
          hover: false,
          name: RouteNames.StudentCenterPersonalInfo,
          visible: true,
        },

        {
          key: 'archives',
          icon0: '',
          icon1: '',
          title: '个人档案',
          active: false,
          hover: false,
          name: RouteNames.Archive,
          visible: true,
        },
        // {
        //   key: 'security-archives',
        //   icon0: '',
        //   icon1: '',
        //   title: '安全档案',
        //   active: false,
        //   hover: false,
        //   name: RouteNames.StudentSecurityArchives,
        // },
        {
          key: 'ts',
          icon0: '',
          icon1: '',
          title: '师带徒',
          active: false,
          name: RouteNames.StudentTeacher,
          visible:
            this.currentUserTenant.masterFlag === models.Enums.Flag.开启 && this.userInfo.masterApprentice.userIs !== 1,
        },
        {
          key: 'issue',
          name: RouteNames.IssuesFeedback,
          icon0: '',
          icon1: '',
          title: '问题反馈',
          active: false,
          visible: true,
        },
        {
          key: 'about-us',
          name: RouteNames.AboutUS,
          icon0: '',
          icon1: '',
          title: '关于我们',
          active: false,
          visible: true,
        },
      ]
        .map(navigate => ({
          ...navigate,
          icon0: require(`./assets/image/icon-${navigate.key}-nor.png`),
          icon1: require(`./assets/image/icon-${navigate.key}-pro.png`),
        }))
        .map(navigate => ({
          ...navigate,
          active: navigate.name === name,
        }))
        .filter(v => v.visible)
    },

    /**
     * @param {{key: string; icon0: string; icon1: string; title: string; active: boolean; name: string; params: {[key: string]: any}; hover: boolean}} nav
     */
    onNavigateClick(nav) {
      this.navigates.forEach((navigate) => {
        navigate.active = navigate.key === nav.key
      })

      this.$router.push({ name: nav.name, params: { ...nav.params } })
    },

    /**
     * @param {{key: string; icon0: string; icon1: string; title: string; active: boolean; name: string; params: {[key: string]: any}; hover: boolean}} [nav]
     */
    onNavigateHover(nav) {
      this.navigates.forEach((navigate) => {
        if (nav) {
          navigate.hover = navigate.key === nav.key
        }
        else {
          navigate.hover = false
        }
      })
    },
  },
}
</script>

<template>
  <div class="navigate-side-bar">
    <StudentArchives />

    <div class="navigate-list">
      <div
        v-for="navigate of navigates"
        :key="navigate.key"
        class="navigate-list-item"
        @click="() => onNavigateClick(navigate)"
        @mouseenter="() => onNavigateHover(navigate)"
        @mouseleave="() => onNavigateHover()"
      >
        <div
          class="icon"
          :style="{ backgroundImage: `url(${navigate.active || navigate.hover ? navigate.icon1 : navigate.icon0})` }"
        ></div>
        <span class="label" :class="navigate.active ? 'active' : ''">{{ navigate.title }}</span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.navigate-side-bar {
  position: sticky;
  top: 10px;
  width: 235px;
  min-width: 235px;
  background: #fff;
  border: 1px solid #e5e5e5;

  .navigate-list {
    padding-bottom: 20px;

    &-item {
      display: flex;
      gap: 10px;
      align-items: center;
      margin: 0 25px;
      padding: 15px;
      border-top: 1px solid #eee;
      cursor: pointer;

      .icon {
        width: 14px;
        height: 18px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        transition: all 0.2s;
      }

      .label {
        color: #666;
        transition: all 0.2s;

        &.active {
          color: #2196f3;
        }
      }

      &:hover {
        .label {
          color: #2196f3;
        }
      }
    }
  }
}
</style>
