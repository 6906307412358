var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "student-archives" }, [
    _c("img", {
      directives: [
        { name: "ali-oss", rawName: "v-ali-oss" },
        { name: "avatar-load-error", rawName: "v-avatar-load-error" },
      ],
      staticClass: "student-avatar",
      attrs: { src: _vm.userInfo.avatar, alt: "" },
    }),
    _c("span", { staticClass: "student-name" }, [
      _vm._v(_vm._s(_vm.userInfo.realName)),
    ]),
    _c("div", { staticClass: "archives", staticStyle: { display: "none" } }, [
      _c(
        "div",
        { staticClass: "archive-item", on: { click: _vm.onIntegralClick } },
        [
          _c("img", {
            staticClass: "archive-item-icon",
            attrs: {
              src: require("./assets/image/icon-integral-mini.png"),
              alt: "",
            },
          }),
          _c("span", { staticClass: "archive-item-label" }, [
            _vm._v("积分 " + _vm._s(_vm.userInfo.stat.score)),
          ]),
        ]
      ),
      _vm.currentUserTenant.certificateFlag === _vm.models.Enums.Flag.开启
        ? _c(
            "div",
            { staticClass: "archive-item", on: { click: _vm.onCertClick } },
            [
              _c("img", {
                staticClass: "archive-item-icon",
                attrs: {
                  src: require("./assets/image/icon-certificate-mini.png"),
                  alt: "",
                },
              }),
              _c("span", { staticClass: "archive-item-label" }, [
                _vm._v("证书 " + _vm._s(_vm.userInfo.stat.certificateCount)),
              ]),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }