<script>
import { defineComponent } from '@vue/composition-api'
import { mapState } from 'vuex'
import Cookies from 'js-cookie'
import { request, utils } from '@qx-student-client/common'
import dayjs from 'dayjs'
import ExamPreInstructions from './components/ExamPreInstructions.vue'
import { RouteNames, whiteList } from '@/router'

export default defineComponent({
  name: 'App',
  components: { ExamPreInstructions },
  data() {
    return {
      examDialog: {
        visible: false,
        exam: null,
      },
    }
  },
  computed: {
    ...mapState({
      /**
       * @returns {import("@/types/models/WebsiteProfile").WebsiteProfile['enterprise']} 企业信息
       */
      enterprise: state => state.websiteProfile.enterprise,

      /**
       * @returns {import("@qx-student-client/common").request.integral.CurrentUserIntegralRuleLisItem[]} 积分规则
       */
      integralRules: state => state.integralRules,

      /**
       * @returns {import("@qx-student-client/common").models.User & import("@qx-student-client/common").models.Authentication} 用户信息
       */
      userInfo: state => state.userInfo,
    }),
  },
  watch: {
    /**
     * @param {import("@/types/models/WebsiteProfile").WebsiteProfile['enterprise']} value
     */
    enterprise(value) {
      if (value && value.company) {
        document.title = value.company
      }
    },

    /**
     * 监听路由变化
     */
    $route() {
      if (!whiteList.includes(this.$route.name)) {
        this.onCheckDailyLogin()
      }
    },
  },
  created() {
    if (this.enterprise && this.enterprise.company) {
      document.title = this.enterprise.company
    }
    document.addEventListener('visibilitychange', this.onCheckUserInfo)
  },
  beforeDestroy() {
    document.addEventListener('visibilitychange', this.onCheckUserInfo)
  },
  methods: {
    /**
     * 判断是否需要请求登录积分的接口
     */
    async onCheckDailyLogin() {
      // 如果当前账号的每日积分规则没有被打开的话则不进行请求
      if (!Cookies.get(utils.Constants.COOKIE_TOKEN_KEY) || !this.userInfo) {
        return
      }

      const tenant = this.userInfo.tenantList.find(t => t.select)
      if (!tenant) {
        return
      }

      // 每日登录积分数据请求
      const lastLoginTimeKey = `${utils.Constants.STORAGE_DAILY_LOGIN}_${tenant.tenantId}_${this.userInfo.account}`
      const lastLoginTime = localStorage.getItem(lastLoginTimeKey)
      const rule = this.integralRules.filter(r => r.status === 1 && r.integralType === 5)
      if (rule.length) {
        if (!lastLoginTime || dayjs(lastLoginTime).isBefore(Date.now(), 'd')) {
          // 没有登录积分信息或者登录积分时间要晚于当前时间则进行操作
          request.integral.dailyLogin().then((response) => {
            if (response.code === 200) {
              localStorage.setItem(lastLoginTimeKey, dayjs(Date.now()).format('YYYY-MM-DD'))
            }
          })
        }
      }

      // 每日登录统计数据
      const loginStatLastLoginTime = localStorage.getItem(`qx-stat-pc-last-login-time-${tenant.tenantId}_${this.userInfo.account}`)
      if (!loginStatLastLoginTime || dayjs(loginStatLastLoginTime).isBefore(Date.now(), 'd')) {
        // 当不存在最后登录时间并且最后登录时间在之前日期(天)的时候进行登录数据统计的请求
        request.dataTracking.submitLoginPersonCount({ clientType: 1 })
          .then((response) => {
            if (response.code === 200) {
              localStorage.setItem(`qx-stat-pc-last-login-time-${tenant.tenantId}_${this.userInfo.account}`, dayjs(Date.now()).format('YYYY-MM-DD'))
            }
          })
      }

      // 判断是否为消息跳转
      const queryParamsString = localStorage.getItem('/virtually-path/message')
      if (queryParamsString) {
        /**
         * @type {{id: string; contentId: string; contentType: string; t: string;type: string}}
         */
        const queryParams = JSON.parse(queryParamsString)
        const t = this.userInfo.tenantList.find(t => t.tenantId === queryParams.t)

        // 1 课程详情 2 培训任务 3 考试任务
        if (queryParams.contentType === '1') { // 跳转到课程详情
          if (t) {
            if (!t.select) {
              // 切换机构
              const response = await request.authentication.switchTenant(this.userInfo.account, t.tenantId)
              if (response.code === 200) {
                location.reload()
              }
              else {
                this.$message.warning({ message: '当前没有访问内容的权限', offset: 100 })
                localStorage.removeItem('/virtually-path/message')
              }
            }
            else {
              // 查看详情
              const response = await request.course.requestCourseAuth(queryParams.contentId)
              if (response.code === 200) {
                // 跳转操作
                const auth = response.data
                if (auth) {
                  // 跳转
                  this.$router.push({ name: RouteNames.CourseDetails, params: { id: queryParams.contentId } })
                }
                else {
                  // 提示没有课程权限
                  this.$message.warning({ message: '当前没有访问内容的权限', offset: 100 })
                }
                localStorage.removeItem('/virtually-path/message')
              }
            }
          }
          else {
            // 提示没有机构权限
            this.$message.warning({ message: '当前没有访问内容的权限', offset: 100 })
            localStorage.removeItem('/virtually-path/message')
          }
        }
        else if (queryParams.contentType === '2') { // 培训任务
          if (t) {
            if (!t.select) {
              // 切换机构
              const response = await request.authentication.switchTenant(this.userInfo.account, t.tenantId)
              if (response.code === 200) {
                location.reload()
              }
              else {
                this.$message.warning({ message: '当前没有访问内容的权限', offset: 100 })
                localStorage.removeItem('/virtually-path/message')
              }
            }
            else {
              // 查看详情
              const response = await request.course.requestCourseAuth(queryParams.contentId)
              if (response.code === 200) {
                // 跳转操作
                const auth = response.data
                if (auth) {
                  // 跳转
                  this.$router.push({ name: RouteNames.TasksTrainingPlan, params: { id: queryParams.contentId } })
                }
                else {
                  // 提示没有课程权限
                  this.$message.warning({ message: '当前没有访问内容的权限', offset: 100 })
                }
                localStorage.removeItem('/virtually-path/message')
              }
            }
          }
          else {
            // 提示没有机构权限
            this.$message.warning({ message: '当前没有访问内容的权限', offset: 100 })
            localStorage.removeItem('/virtually-path/message')
          }
        }
        else if (queryParams.contentType === '3') { // 跳转到考试任务
          if (t) {
            if (!t.select) {
              // 切换机构
              const response = await request.authentication.switchTenant(this.userInfo.account, t.tenantId)
              if (response.code === 200) {
                location.reload()
              }
              else {
                this.$message.warning({ message: '当前没有访问内容的权限', offset: 100 })
                localStorage.removeItem('/virtually-path/message')
              }
            }
            else {
              if (queryParams.type === '7') {
                if (this.userInfo.examinationFlag === 1) {
                  request.markingPaper.getPaperMarkingAuth({ examinationId: queryParams.contentId })
                    .then((response) => {
                      if (response.code === 200) {
                        if (response.data) {
                          // 阅卷任务
                          this.$router.push({ name: RouteNames.StudentCenterTasks, params: { active: 'markPaper' }, query: { id: queryParams.contentId } })
                        }
                        else {
                          this.$message.warning({ message: '当前没有考试阅卷的权限', offset: 100 })
                        }
                      }
                    })
                    .catch(() => {
                      this.$message.warning({ message: '当前没有考试阅卷的权限', offset: 100 })
                    })
                }
                else {
                  this.$message.warning({ message: '当前没有考试阅卷的权限', offset: 100 })
                }
                localStorage.removeItem('/virtually-path/message')
                return
              }
              // 查看详情
              const response = await request.exam.requestExamAuth(queryParams.contentId)
              if (response.code === 200) {
                // 跳转操作
                const auth = response.data
                if (auth.length) {
                  // 判断是继续考试还是重新考试
                  if (auth[0].addIs !== 1) {
                    // 新的考试
                    // 弹框
                    request.exam.requestExamIntro({ examinationId: queryParams.contentId })
                      .then((response) => {
                        if (response.code === 200) {
                          this.examDialog.exam = response.data

                          this.$nextTick(() => {
                            this.examDialog.visible = true
                          })
                        }
                      })
                  }
                  else {
                    // 继续考试
                    // 跳转
                    this.$router.push({ name: RouteNames.ExamAnswering, query: { newExam: false, recordId: auth[0].recordId } })
                  }
                  localStorage.removeItem('/virtually-path/message')
                }
                else {
                  // 提示没有课程权限
                  this.$message.warning({ message: '当前没有访问内容的权限', offset: 100 })
                  localStorage.removeItem('/virtually-path/message')
                }
              }
            }
          }
          else {
            // 提示没有机构权限
            this.$message.warning({ message: '当前没有访问内容的权限', offset: 100 })
            localStorage.removeItem('/virtually-path/message')
          }
        }
      }
    },
    onCheckUserInfo() {
      if (document.hidden) {
        return
      }

      // 展示的时候检查一下用户的token的信息信息，如果token信息丢失或者两个token不一致，则在下次展示页面的时候进行刷新页面
      const token = Cookies.get(utils.Constants.COOKIE_TOKEN_KEY)
      const requestToken = request.request.defaults.headers['Crazy-Auth']
      const isDiffToken = requestToken && requestToken.split(' ')[1] !== token
      if (!token) {
        if (!whiteList.includes(this.$route.name)) {
          // 刷新触发权限判断回到登录页面
          window.location.reload()
        }
      }
      else if (isDiffToken) {
        request.request.defaults.headers['Crazy-Auth'] = `${requestToken.split(' ')[0]} ${token}`
        // 当用户信息不一致时直接回到首页，防止切换用户后看到数据不匹配的页面
        location.href = location.origin
      }
    },

    onExamTipConfirm(exam) {
      this.$router.push({ name: RouteNames.ExamAnswering, query: { newExam: true, id: exam.id } })
      this.onExamTipClose()
    },

    onExamTipClose() {
      this.examDialog.visible = false
      this.$nextTick(() => {
        this.examDialog.exam = null
      })
    },
  },
})
</script>

<template>
  <div id="root">
    <el-dialog
      title="考前提示"
      :visible.sync="examDialog.visible"
      width="800px"
      :before-close="onExamTipClose"
      append-to-body
    >
      <ExamPreInstructions :exam="examDialog.exam" @close="onExamTipClose" @confirm="onExamTipConfirm" />
      <template #footer> </template>
    </el-dialog>
    <router-view />
  </div>
</template>

<style lang="scss">
:root {
  color: #333;
  font: 16px/1.5 'PingFang SC', '微软雅黑', 'Microsoft YaHei', Helvetica, 'Helvetica Neue', Tahoma, Arial, sans-serif;
  background-color: rgb(255 255 255 / 100%);
  color-scheme: light dark;
  font-synthesis: none;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
}

::placeholder {
  font-family: 'PingFang SC', '微软雅黑', 'Microsoft YaHei', Helvetica, 'Helvetica Neue', Tahoma, Arial, sans-serif;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  transition: width 0.3s;
}

::-webkit-scrollbar-thumb {
  background-color: #bbb;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
}

// 修复自动填充时的样式问题
input:-internal-autofill-previewed,
input:-internal-autofill-selected {
  -webkit-text-fill-color: #333;
  transition: background-color 5000s ease-in-out 0s;
}

html,
body,
#app,
#root {
  scroll-behavior: smooth;
  width: 100%;
  min-width: $view-content-min-width;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto hidden;

  /* 修复macos触控双指左滑返回的问题 */
  overscroll-behavior-x: none;
}

p {
  margin: 0;
}

* {
  box-sizing: border-box;
  word-break: break-all;
}

img {
  object-fit: fill;
}

.icon-deg-90 {
  transform: rotateZ(90deg);
}

.word-breaks-format {
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
}
</style>
