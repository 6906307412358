var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !!_vm.exam
    ? _c("div", { staticClass: "exam-dialog-content" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v(" " + _vm._s(_vm.exam.name) + " "),
        ]),
        _c("div", { staticClass: "range" }, [
          _vm._v(
            _vm._s(_vm.exam.ststTime.slice(0, -3)) +
              " ~ " +
              _vm._s(_vm.exam.endTime.slice(0, -3))
          ),
        ]),
        _c("div", { staticClass: "fields" }, [
          _c("div", { staticClass: "field" }, [
            _c("div", { staticClass: "label" }, [_vm._v("考试总分")]),
            _c("div", { staticClass: "value" }, [
              _c("span", { staticClass: "big" }, [
                _vm._v(_vm._s(_vm.exam.paperScore)),
              ]),
              _vm._v("分 "),
            ]),
          ]),
          _c("div", { staticClass: "field" }, [
            _c("div", { staticClass: "label" }, [_vm._v("及格分")]),
            _c("div", { staticClass: "value" }, [
              _c("span", { staticClass: "big" }, [
                _vm._v(_vm._s(_vm.exam.passScore)),
              ]),
              _vm._v("分 "),
            ]),
          ]),
          _c("div", { staticClass: "field" }, [
            _c("div", { staticClass: "label" }, [_vm._v("考试时长")]),
            _c("div", { staticClass: "value" }, [
              _c("span", { staticClass: "big" }, [
                _vm._v(_vm._s(_vm.exam.examTime)),
              ]),
              _vm._v("分钟 "),
            ]),
          ]),
          _c("div", { staticClass: "field" }, [
            _c("div", { staticClass: "label" }, [_vm._v("考试次数")]),
            _c("div", { staticClass: "value" }, [
              _c("span", { staticClass: "big" }, [
                _vm._v(_vm._s(_vm.exam.frequencyNum)),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "field" }, [
            _c("div", { staticClass: "label" }, [_vm._v("切屏次数")]),
            _c("div", { staticClass: "value" }, [
              _c("span", { staticClass: "big" }, [
                _vm._v(_vm._s(_vm.exam.screenNums)),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "tips" }, [
          _vm._v("进入考试后，计时结束时将自动交卷，退出后继续计时"),
        ]),
        _c(
          "div",
          { staticClass: "footer" },
          [
            _c(
              "el-button",
              {
                staticClass: "button",
                attrs: { type: "default", size: "medium", plain: "" },
                on: {
                  click: function ($event) {
                    return _vm.$emit("close")
                  },
                },
              },
              [_vm._v("取消")]
            ),
            _c(
              "el-button",
              {
                staticClass: "button",
                attrs: { type: "primary", size: "medium" },
                on: { click: _vm.onConfirm },
              },
              [_vm._v("开始考试")]
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }